<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <g clip-path="url(#clip0_1640_22827)">
      <path
        d="M9.77992 9.73562C12.1534 9.73562 14.0774 7.81157 14.0774 5.43813C14.0774 3.06468 12.1534 1.14062 9.77992 1.14062C7.40648 1.14062 5.48242 3.06468 5.48242 5.43813C5.48242 7.81157 7.40648 9.73562 9.77992 9.73562Z"
        stroke="#002348" stroke-width="1.91" stroke-miterlimit="10" />
      <path
        d="M1.9043 17.61L2.1818 16.0725C2.50962 14.2969 3.4491 12.6921 4.83703 11.5371C6.22496 10.3821 7.97364 9.74979 9.7793 9.75C11.5871 9.75044 13.3375 10.3849 14.7257 11.5429C16.114 12.7009 17.0521 14.3091 17.3768 16.0875L17.6543 17.625"
        stroke="#002348" stroke-width="1.91" stroke-miterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_1640_22827">
        <rect width="18" height="18" fill="white" transform="translate(0.779297)" />
      </clipPath>
    </defs>
  </svg>
</template>
