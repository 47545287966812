<template>
  <div>
    <BaseCard :card-name="tr('Tumor Board Sessions')" :spinnerOn="filingSpinner">
      <div class="base-tb-container">
        <div class="tab-container">
          <div class="tab" :class="{ 'tab-active': tabHighActive }" id="tb-all-tab" @click="tabTBClick('High')">
            {{ tr('Previous Sessions') }}
          </div>
          <div class="tab" :class="{ 'tab-active': tabPrevActive }" id="tb-gen-tab" @click="tabTBClick('Prev')">
            {{ tr(dynamicTabHeading) }}</div>
        </div>
        <div class="tb-list-container">
          <div class="tb-container" :class="{ 'tb-container-active': tabHighActive }" id="tb-all-list">
            <TumorBoardPreviousSessions />
          </div>
          <div class="tb-container" :class="{ 'tb-container-active': tabPrevActive }" id="tb-gen-list">
            <TumorBoardUpcomingSessions />
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import BaseCard from '../BaseCard/BaseCard.vue'
import TumorBoardPreviousSessions from '../TumorBoardSessions/TumorBoardPreviousSessions.vue'
import TumorBoardUpcomingSessions from '../TumorBoardSessions/TumorBoardUpcomingSession.vue'
//import { fetchWithTimeout } from '@/common/shared'
//import getEnv from '@/utils/env'
//import { format } from 'date-fns'
import { useStore } from 'vuex'
//import { calculateLastAccessDate } from '@/common/shared.js'

const store = useStore()
const tr = (x) => x // inject('tr')
const tabHighActive = ref(true)
const tabPrevActive = ref(false)
const dynamicTabHeading = ref('Upcoming Session')
const patient = computed(() => store.getters.session.patient)
const tumorBoardSessionsData = computed(() => store.getters.session.tumorBoardSessionsData)

watch(tumorBoardSessionsData, async (newTumorBoardSessionsData) => {
  if (!newTumorBoardSessionsData) return {}
  const upcomingSessionData = newTumorBoardSessionsData.Upcoming
  dynamicTabHeading.value = !upcomingSessionData.length && patient ? 'New Session' : 'Upcoming Session'
})

const clearTBTabs = () => {
  tabHighActive.value = false
  tabPrevActive.value = false
}

const tabLookup = { 'High': tabHighActive, 'Prev': tabPrevActive }

const tabTBClick = (tab) => {
  clearTBTabs()
  tabLookup[tab].value = true
}

//const patientId = '0x444531'  // TODO

const filingSpinner = ref(false)

onMounted(async () => {
  // TODO: pull TB data for patient
})
</script>

<style lang="scss" scoped>
.base-tb-container {
  overflow-y: auto;
  height: 300px;
  margin-bottom: 30px;
  transition: opacity linear 1000ms;
}

.tb-container {
  display: none;
  padding: 15px;
}

.tb-container-active {
  display: flex;
}

.tb-container {
  overflow: auto;
}
</style>

