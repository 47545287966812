// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { store } from '@/store'
import { fetchWithTimeout, themeColors } from '@/common/shared.js'
import getEnv from '@/utils/env'

const injectToolId = (html, toolId) => {
  const newScript = `<script type="text/javascript">
window.ux2 = {
  toolId: '${toolId}',
};
</script></head>`
  return html.replace(/<\/head>/i, newScript)
}

export const toolScrollbarThemeChangerScript = () => {
  return `<script type="text/javascript">
window.parent.ux2.addEventListener('change', () => {
  const themeColors = window.parent.ux2.themeColors
  const newInnerHtml = \`html {
  --appScrollBarBackgroundColor: \${themeColors.toolScrollBarBackgroundColor};
  --appScrollBarColor: \${themeColors.toolScrollBarColor};
  --accentColor: \${themeColors.accentColor};
}\`
  const allStyleEls = document.querySelectorAll('style')
  const htmlStyleEl = allStyleEls[allStyleEls.length-2]
  htmlStyleEl.innerHTML = newInnerHtml
})
</script>`
}

export const toolScrollbarStyle = () => {
  return `<style>
html {
  --appScrollBarBackgroundColor: ${themeColors[store.getters.currentThemeName].toolScrollBarBackgroundColor};
  --appScrollBarColor: ${themeColors[store.getters.currentThemeName].toolScrollBarColor};
  --accentColor: ${themeColors[store.getters.currentThemeName].accentColor};
}
</style><style>
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
::-webkit-scrollbar-track {
  background: var(--appScrollBarBackgroundColor);
}
::-webkit-scrollbar-thumb {
  background-color: var(--appScrollBarColor);
  border-radius: 12px;
  border: 2px solid var(--appScrollBarBackgroundColor);
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--accentColor);
}</style></body></html>`
}

const injectIframeScrollbarAndToolId = (html, toolId) => {
  const scrollbarStyle = toolScrollbarThemeChangerScript() + toolScrollbarStyle()
  const newHtml = injectToolId(html, toolId)
  return newHtml.replace(/<\/body>\s*<\/html>\s*$/im, scrollbarStyle)
}

export async function loadTool(method, url, body, iframe, loadingStatus, toolId) {
  const defaultToolTimeout = getEnv('VUE_APP_TOOL_TIMEOUT_MS') || 15000
  const fetchParams = {
    body,  // may be undefined
    timeout: defaultToolTimeout,
    method,
    headers: {
      'Accept': 'text/html',
      'Authorization': 'Bearer ' + store.getters.jwt,
      'Content-Type': 'application/json',
    },
  }

  try {
    const response = await fetchWithTimeout(url, fetchParams)
    if (!response.ok) {
      loadingStatus.value = 'tool error'
      console.log(`HTTP error, status=${response.status}`)
      return
    }
    const originalResult = await response.text()
    if (!iframe.value) {
      console.log('iframe no longer exists; ignoring response')
      return
    }

    const result = injectIframeScrollbarAndToolId(originalResult, toolId)

    if (typeof iframe.value.srcdoc !== 'undefined') {
      iframe.value.srcdoc = result
    } else {
      iframe.value.sandbox = 'allow-same-origin'
      iframe.value.contentWindow.document.open()
      iframe.value.contentWindow.document.write(result)
      iframe.value.contentWindow.document.close()
    }
    loadingStatus.value = 'loaded'
  } catch (e) {
    if (e.name === 'AbortError') {
      loadingStatus.value = 'timeout error'
    } else {
      loadingStatus.value = 'tool error'
      console.log('ERROR:', e)
    }
  }
}

export const clearIframe = (iframe) => {
  if (!iframe.value) { return }
  if (typeof iframe.value.srcdoc !== 'undefined') {
    iframe.value.srcdoc = ''
  } else {
    iframe.value.sandbox = 'allow-same-origin'
    iframe.value.contentWindow.document.open()
    iframe.value.contentWindow.document.write('')
    iframe.value.contentWindow.document.close()
  }
}
