<template>
  <base-modal :open="open" @close="onCancel">
    <div class="header">
      <button class="modal-close-button" @click.prevent="onCancel"><span
          class="material-symbols-outlined size-24  icon-button-to-primary">close</span></button>
      <h2>{{ tr('Find a patient') }}</h2>
      <div class="search-line">
        <div class="search-bar" :style="searchInputStyle">
          <input type="text" :placeholder="tr('Search for a patient by name or MRN')" ref="searchInputEl"
            @keydown="performSearch" v-model="searchQuery" class="input" />
          <div class="close-icon icon-button-to-primary" @click.prevent="onCloseIconClick">
            <span class="material-symbols-outlined size-20">
              close
            </span>
          </div>
        </div>
        <button class="primary-filled-button" @click.prevent="onSearchForPatient">{{ tr('Search')
        }}</button>
      </div>
    </div>

    <div class="body-wrapper">
      <table class="table-standard" v-if="patients.length !== 0 && searchStatus !== 'searching'">
        <thead ref="patientTableHeader">
          <tr>
            <th class="patient-name-header">
              <button class="sort-button" @click.prevent="onClickSortTable(0)">
                <span class="underline">{{ tr('Name') }}</span>&nbsp;

                <div class="arrow-container">
                  <span class="material-symbols-outlined icon-button-close arrow"
                    v-show="currentSortedColumnIndex == 0 && sortOrder">arrow_drop_down</span>

                  <span class="material-symbols-outlined icon-button-close arrow"
                    v-show="currentSortedColumnIndex == 0 && !sortOrder">arrow_drop_up</span>
                </div>
              </button>
            </th>
            <th><button class="sort-button" @click.prevent="onClickSortTable(1)"><span class="underline">{{ tr('Gender')
            }}</span>&nbsp;
                <div class="arrow-container">
                  <span class="material-symbols-outlined icon-button-close arrow"
                    v-show="currentSortedColumnIndex == 1 && sortOrder">arrow_drop_down</span>

                  <span class="material-symbols-outlined icon-button-close arrow"
                    v-show="currentSortedColumnIndex == 1 && !sortOrder">arrow_drop_up</span>
                </div>
              </button>
            </th>
            <th><button class="sort-button" @click.prevent="onClickSortTable(2)">
                <span class="underline">{{ tr('Birthdate')
                }}</span>&nbsp;
                <div class="arrow-container">
                  <span class="material-symbols-outlined icon-button-close arrow"
                    v-show="currentSortedColumnIndex == 2 && sortOrder">arrow_drop_down</span>

                  <span class="material-symbols-outlined icon-button-close arrow"
                    v-show="currentSortedColumnIndex == 2 && !sortOrder">arrow_drop_up</span>
                </div>
              </button>
            </th>
            <th><button class="sort-button" @click.prevent="onClickSortTable(3)"><span class="underline">{{ tr('MRN')
            }}</span>&nbsp;
                <div class="arrow-container">
                  <span class="material-symbols-outlined icon-button-close arrow"
                    v-show="currentSortedColumnIndex == 3 && sortOrder">arrow_drop_down</span>

                  <span class="material-symbols-outlined icon-button-close arrow"
                    v-show="currentSortedColumnIndex == 3 && !sortOrder">arrow_drop_up</span>
                </div>
              </button>
            </th>
          </tr>
        </thead>
        <tbody class="patient-table-body">
          <tr v-for="patient in patients" :key="patient.id" @click.prevent="onSelectPatient(patient)">
            <td class="patient-name">
              <button class="select-patient">{{ patient.name }}</button>
            </td>
            <td class="patient-gender">
              {{ patient.gender }}
            </td>
            <td>
              {{ patient.birthdate }}
            </td>
            <td class="font-monospace" :title="patient.id">
              {{ shortenId(patient.id) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <p v-if="patients.length === 0 && searchStatus === 'done'">{{ tr('No patients found.') }}</p>
    <base-spinner class="base-spinner" v-if="searchStatus === 'searching'"></base-spinner>
  </base-modal>
</template>

<script setup>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, defineEmits, defineProps, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'

import BaseModal from '@/ux3/components/BaseModal.vue'
import BaseSpinner from '@/components/ui/icons/BaseSpinner.vue'

import { search } from '@/components/ui/FindPatientModal/search.js'
import { getScrollbarWidth, shortenId } from '@/common/shared.js'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['close'])

const columnCompareFuncs = {
  0: (a, b) => a.name.localeCompare(b.name),
  1: (a, b) => a.gender.localeCompare(b.gender),
  2: (a, b) => a.birthdate.localeCompare(b.birthdate),
  3: (a, b) => a.id.localeCompare(b.id),
}
const compareFunc = (columnIndex, ascending) => {
  return (a, b) => {
    const f = columnCompareFuncs[columnIndex]
    return ascending ? f(a, b) : -f(a, b)
  }
}

const store = useStore()
const tr = inject('tr')
const tutorialNext = inject('tutorialNext')

const searchQuery = ref('')

const patients = ref([])

const patientTableHeader = ref(null)
const onSearchForPatient = async () => {
  tutorialNext()
  const query = searchQuery.value
  searchStatus.value = 'searching'
  const results = await search(query)
  patients.value = results
  sortTable(0)
  searchStatus.value = 'done'

  setTimeout(() => {
    if (patientTableHeader.value) {
      const scrollbarWidth = getScrollbarWidth()
      patientTableHeader.value.style.width = `${patientTableHeader.value.offsetWidth - scrollbarWidth}px`
    }
  }, 50)
}

const searchInputEl = ref(null)
const isOpen = computed(() => props.open)
watch(isOpen, (newValue) => {
  if (!newValue) { return }
  setTimeout(() => searchInputEl.value.focus(), 100)
})

const performSearch = (e) => {
  if (e.keyCode !== 13) { return }
  onSearchForPatient()
}

const onSelectPatient = (patient) => {
  console.log('store.getters.tools', store.getters.tools)
  store.dispatch('setSessionPatient', { patient, publish: true })
  emit('close')
  setTimeout(tutorialNext, 50)
}

const searchStatus = ref('pending')

const onCancel = () => {
  emit('close')
}



let lastSortedColumnIndex = 0
let lastSortOrder = true
const currentSortedColumnIndex = ref(0)
const sortOrder = ref(true)

const onCloseIconClick = () => searchQuery.value = ''

const onClickSortTable = (index) => {
  if (index !== currentSortedColumnIndex.value) {
    lastSortedColumnIndex = currentSortedColumnIndex.value
    lastSortOrder = sortOrder.value
    sortOrder.value = true
  } else {
    sortOrder.value = !sortOrder.value
  }
  currentSortedColumnIndex.value = index
  sortTable(index)
}

const sortTable = (index) => {
  const firstCompare = compareFunc(index, sortOrder.value)
  const secondCompare = compareFunc(lastSortedColumnIndex, lastSortOrder)
  const bothCompares = (a, b) => {
    const result1 = firstCompare(a, b)
    if (result1 === 0) { return secondCompare(a, b) }
    return result1
  }
  patients.value.sort(bothCompares)
}
</script>

<style lang="scss" scoped>
h2 {
  color: var(--Grey700);
  padding-top: 1rem;
}

.table-standard {
  width: 750px;

  th {
    position: sticky;
    top: 0;
    background: var(--Grey800);
  }
}

.body-wrapper {
  overflow-y: auto;
  max-height: 65vh;
  padding-bottom: 2.245rem;
}

.input {
  flex-grow: 1;
  font: inherit;
  background-color: var(--Grey100);
  border: none !important;
  outline: none;
  // border-bottom: 1px solid #000;
  padding: 5px;
}

.search-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 305px;
  border-radius: 6px;
  border: 2px solid var(--Grey300);
  background-color: var(--Grey100);

  input {
    font-size: 1rem;
  }
}

.close-icon {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
}

.search-button {
  font: inherit;
  margin: 1rem 3rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.sort-button {
  font: inherit;
  color: inherit;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;

  &:hover,
  &:focus,
  &:focus:not(:focus-visible) {
    transform: none;

    &:hover {
      transform: none;
    }
  }
}

.sort-button:hover .underline {
  text-decoration: underline;
}

.arrow-container {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
}

.patient-gender {
  text-align: left;
}

.patient-name,
.patient-name-header {
  text-align: left;
  padding-left: 1.5rem;
}

.select-patient {
  font: inherit;
  color: inherit;
  text-align: left;
  padding: 0;
  font-size: 1em;
  background: none;
  border: none;
  cursor: pointer;
}

.font-monospace {
  font-family: 'Roboto Mono';
}

.base-spinner {
  display: block;
  margin: auto;
  width: 100px;
  height: 100px;
  margin-bottom: 6rem;
}

.modal-footer {
  width: 100%;
  margin: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.modal-close-button {
  font: inherit;
  cursor: pointer;
  border-radius: 40px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: all linear 200ms;
  background-color: transparent;
  border: none;
  outline: none;

  &:hover,
  &:focus {
    color: var(--Black);
  }

  &:focus:not(:focus-visible) {
    color: var(--Grey500);

    &:hover {
      color: var(--Black);
    }
  }
}
</style>
