<template>
  <div class="base-card card-elevated">
    <div class="card-header" :class="{ 'spinner': props.spinnerOn }">
      <div class="card-name">{{ props.cardName }}</div>
      <div class="card-icon">
        <slot name="icon">
          <span class="material-symbols-outlined icon-button-to-primary" @click.prevent="closeCenterColumn()"
            v-if="props.icon === 'close'">{{
              props.icon }}</span>
        </slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { closeCenterColumn } from '@/ux3/components/CenterColumnManagement/centerColumnManagement.js'

const props = defineProps({
  cardName: String,
  icon: String,
  spinnerOn: Boolean,
})

</script>

<style lang="scss" scoped></style>



